<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 g">
      <div class="card card-body">
        <div class="row">
          <div class="col-12 col-lg-4 c g">
            <div class="form-group">
              <label for="">{{ e("date-from") }}</label>
              <input
                v-if="!hijri"
                type="date"
                class="form-control"
                v-model="start"
              />
              <date-picker
                v-if="hijri"
                v-model="start"
                :locale="'ar-sa'"
                :calendar="'hijri'"
              ></date-picker>
              <span v-if="hijri">
                <h2 class="text-danger g text-cneter" v-if="start">
                  {{ start }} هـ
                </h2>
              </span>
            </div>

            <div class="form-group">
              <label for="">{{ e("date-to") }}</label>
              <input
                v-if="!hijri"
                type="date"
                class="form-control"
                v-model="end"
              />
              <date-picker
                v-if="hijri"
                v-model="end"
                :locale="'ar-sa'"
                :calendar="'hijri'"
              ></date-picker>
              <span v-if="hijri">
                <h2 class="text-danger g text-cneter" v-if="end">
                  {{ end }} هـ
                </h2>
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <form :action="api + '/reports/download-excel'" method="post">
            <input type="hidden" name="jwt" :value="user.jwt" />
            <input type="hidden" name="from" :value="start" />
            <input type="hidden" name="to" :value="end" />
            <div v-for="device in devices" :key="device.serial_number">
              <div class="form-check">
                <h4 class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="selected[]"
                    id=""
                    :value="device.serial_number"
                  />
                  {{ device.title }}
                </h4>
              </div>
            </div>
            <div>
              <div class="form-check">
                <h4 class="form-check-label">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name="selected[]"
                    id=""
                    value="app"
                  />
                  التطبيق
                </h4>
              </div>
            </div>
            <br />
            <button class="btn btn-success">
              <u>
                <i class="fa fa-download"></i>
                {{ e("download-excel-report") }}
              </u>
            </button>
          </form>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueDatetimeJs from "vue-datetime-js";
import Ripple from "vue-ripple-directive";
var moment = require("moment-timezone");
var moment2 = require("moment-hijri");
const a2e = (s) => s.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d));
import { BOverlay, VBModal, BDropdown, BDropdownItem } from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    datePicker: VueDatetimeJs,
    VBModal,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      loading: true,
      devices: [],
      user: JSON.parse(localStorage.getItem("user")),
      date: null,
      hijri: false,
      start: null,
      end: null,
      api: api,
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  created() {
    if (cdate("2022-02-02").includes("ه")) {
      this.hijri = true;
    } else {
      this.start = new Date().toISOString().split("T")[0];
      this.end = new Date().toISOString().split("T")[0];
    }
    var g = this;
    var _g = this;
    $.post(api + "/devices/index", {
      jwt: this.user.jwt,
    })
      .then(function (response) {
        response = JSON.parse(response);
        if (response.status == 100) {
          _g.devices = response.response;
        } else {
          _g.$toast({
            component: ToastificationContent,
            props: {
              title: e("error"),
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        }
        _g.loading = false;
      })
      .catch(function () {
        _g.$toast({
          component: ToastificationContent,
          props: {
            title: e("error"),
            icon: "TimesIcon",
            variant: "danger",
          },
        });
        _g.loading = false;
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
  },
};
</script>
